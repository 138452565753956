import { product_wastes } from "@/interfaces";

export function dataFormProdctWastes(
  productWastes: product_wastes[]
): product_wastes[] {
  return productWastes.map(
    ({ waste_category_id, waste_id, file, id_product_wastes, _destroy }) => ({
      waste_category_id,
      waste_id,
      id: id_product_wastes,
      photo: file ? file : undefined,
      _destroy,
    })
  );
}

import { apiBaseURL, apiBaseURLV1 } from "@/constants";
import Kitsu from "kitsu";

export const api = new Kitsu({
  baseURL: apiBaseURL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 1000000,
});

export const api_v1 = new Kitsu({
  baseURL: apiBaseURLV1,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 1000000,
});

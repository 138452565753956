import { z } from "zod";

export interface CC_Form {
  id: string;
  center_type?: string;
  collector?: boolean;
  description?: string;
  name?: string;
  notes?: string;
  ticket?: string;
  schedule_id?: string;
  benefits?: string;
  benefits_id?: string;
  address: {
    id: string;
    street?: string;
    exterior?: string;
    interior?: string;
    lng?: number;
    lat?: number;
    state_id?: string;
    town_id?: string;
    zipcode_id?: string;
    settlement_id?: string;
  };
  socials: {
    id?: string;
    social_medium_attributes: {
      id?: string;
      site: string;
      url?: string;
    };
  }[];
  wastes?: {
    id?: string;
    waste_id?: string;
    waste_name?: string;
    _destroy: boolean;
  }[];
  campaign?: {
    id?: string;
    campaign_id?: string;
    name?: string;
    _destroy: boolean;
  }[];
  comments: {
    id?: string;
    description?: string;
    created_at?: string;
    score?: string;
  }[];
  nup?: { id?: string; nup_id?: string; _destroy: boolean };
  nup_id?: string;
  contact: {
    id?: string;
    id_contact?: string;
    contact_type?: string;
    phone?: string;
    phone_ext?: string;
    email?: string;
  };
  stars: number;
  active: boolean;
  image?: string;
  file?: File;
  qr_code?: string;
}

export const WasteSchemaCC = z.object({
  id: z.string().optional(),
  waste_id: z.string().optional(),
  waste_name: z.string().optional(),
  _destroy: z.boolean().optional(),
});

export const CampaignSchemaCC = z.object({
  id: z.string().optional(),
  campaign_id: z.string().optional(),
  name: z.string().optional(),
  _destroy: z.boolean().optional(),
});

// Generated by https://quicktype.io

export interface ResponseSettementData {
  zip_codes: ZipCodes;
  towns: Town[];
  states: State[];
  headers: Headers;
}

export interface Headers {
  "cache-control": string;
  "content-type": string;
}

export interface State {
  id: number;
  name: string;
  state_uid: number;
  created_at: string;
  updated_at: string;
}

export interface Town {
  id: number;
  state_id: number;
  name: string;
  town_uid: number;
  created_at: string;
  updated_at: string;
}

export interface ZipCodes {
  id: number;
  town_id: number;
  code: string;
  created_at: string;
  updated_at: string;
}

// Generated by https://quicktype.io

export interface CollectionCenterResponse {
  id: string;
  type: string;
  address: Address;
  collection_center_benefit: CollectionCenterBenefit;
  collection_center_nup: CollectionCenterNup;
  contacts: CampaignCollectionCenters;
  collection_center_contacts: {
    data: {
      id?: string;
      type?: string;
      contact?: {
        data?: {
          contact_type?: string;
          phone?: string;
          id?: string;
          phone_ext?: string;
        };
      };
    }[];
  };
  collection_center_socials: {
    data: {
      id?: string;
      type?: string;
      social_medium?: {
        data?: {
          url?: string;
          site?: string;
          id?: string;
        };
      };
    }[];
  };
  collection_center_wastes: CampaignCollectionCenters;
  wastes: CampaignCollectionCenters;
  collection_center_comments: CampaignCollectionCenters;
  comments: Comment;
  campaign_collection_centers: CampaignCollectionCenters;
  campaigns: CampaignCollectionCenters;
  schedule_model: { data?: { id?: string } };
  name: string;
  description: null;
  schedule: null;
  score: string;
  center_type: string;
  active: boolean;
  ticket: string;
  collector: boolean;
  notes: null;
  image: null;
  qr_code: null;
}

export interface Address {
  data: AddressData;
}

export interface AddressData {
  id: string;
  type: string;
  street: string;
  exterior: string;
  interior: string;
  comment: null;
  settlement: { data: { id: string } };
  lat: number;
  lng: number;
  pin: string;
  text_address: string;
}

export interface Datum {
  id: string;
  type: Type;
  comment?: Comment;
  phone?: string;
  phone_ext?: string | null;
  contact_type?: number;
  waste_informations?: CampaignCollectionCenters;
  name?: string;
  description?: string;
  active?: boolean;
  platform_visible?: PlatformVisible;
  slug?: string;
  icon?: null | string;
  selected?: boolean;
  waste?: { data?: Datum };
  campaign?: { data: Datum };
}

export interface CampaignCollectionCenters {
  data: Datum[];
}

export interface Comment {
  data: CommentData[];
}

export interface CommentData {
  id: string;
  type: string;
  description: string;
  score: string;
  created_at: string;
}

export enum Type {
  CollectionCenterComment = "collection_center_comment",
  Comment = "comment",
  Contact = "contact",
  Waste = "waste",
  WasteInformation = "waste_information",
}

export enum PlatformVisible {
  VisibleWebVisibleApp = "visible_web_visible_app",
}

export interface CollectionCenterBenefit {
  data: CollectionCenterBenefitData;
}

export interface CollectionCenterBenefitData {
  id: string;
  type: string;
  name: string;
}

export interface CollectionCenterNup {
  data: { id?: string; nup?: { data: { id?: string } } };
}

import { useSession } from "next-auth/react";
import { usePagination } from "./usePagination";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import {
  CCValidationResponse,
  GridCCValidations,
  RejectedOptionsId,
  RejectedValidation,
  RowCCValidations,
  Waste_validation,
  collectionCenterValidations,
} from "@/interfaces";
import { dataServices } from "./dataServices";
import { DateRange } from "react-day-picker";
import { useForm } from "react-hook-form";
import { CampaignsOptions } from "@/constants/campaignsData";
import { useWastesData } from "./useWastesData";
import { serialize } from "object-to-formdata";
import { toast } from "@/components/atoms/shadcn/ui/use-toast";
export type FormFilterValuesCCValidations = {
  globalRange: DateRange;
  search: string;
  type_collection_center_id: string;
  campaign_id: string;
};
export function transform2GridCCValidation(
  arr: RowCCValidations
): GridCCValidations {
  const result: any[][] = [];
  const chunkSize = 3;
  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }
  return result;
}

function getDataToCCValidation(arr: any) {
  return arr.flatMap(
    (dataTest: CCValidationResponse): collectionCenterValidations => {
      return {
        id_cc_validation: dataTest.id ?? "",
        evidence: dataTest?.image ?? undefined,
        id_user: dataTest?.recycler?.data?.id,
        user_name:
          dataTest?.recycler?.data?.first_name &&
          dataTest?.recycler?.data?.last_name
            ? `${dataTest?.recycler?.data?.first_name} ${dataTest?.recycler?.data?.last_name}`
            : undefined,
        date_insert: new Date(dataTest.created_at).toLocaleDateString("es-MX"),
        adress_collection_center: String(
          dataTest.collection_center.data.address.data.text_address
        ),
        campaign_name:
          dataTest.badges.data.length > 0
            ? dataTest.badges.data
                .map((uni_badge) => {
                  return uni_badge.name;
                })
                .join(" ,")
            : undefined,
        name_collection_center: dataTest.collection_center.data.name,
        wastes_validations:
          dataTest.residue_validation_wastes?.data?.map(
            (item: any, idx: number): Waste_validation => {
              return {
                id_waste_valdation: item.id ?? "",
                quantity: item?.quantity ?? "",
                waste_category_id: undefined,
                waste_id: item?.waste.data.id ?? "",
                _destroy: false,
              };
            }
          ) ?? [],
        comment: undefined,
      };
    }
  );
}

export const useCCValidations = (
  per_page: number,
  type_validations: number
) => {
  const { data } = useSession();
  const { control, watch } = useForm<FormFilterValuesCCValidations>({
    defaultValues: {
      globalRange: {
        from: undefined,
        to: undefined,
      },
      search: "",
      campaign_id: "",
      type_collection_center_id: "",
    },
  });
  const {
    currentPage,
    firstPage,
    lastPage,
    nextPage,
    prevPage,
    last_page,
    paginationLabel,
    setTotal,
    setTotalPages,
    setCurrentPage,
    onChangePageSize,
    pageSize,
  } = usePagination(per_page);

  const { wastes, wastes_categories } = useWastesData(data);

  const {
    data: requestData,
    refetch,
    isLoading,
    isFetching,
    isRefetching,
  } = useQuery({
    queryKey: ["cc_validation", currentPage, type_validations, pageSize],
    enabled: data?.user?.token ? true : false,
    queryFn: async () => {
      const results = data?.user?.token
        ? await dataServices({
            url: "/residue_validations",
            params: {
              status: [
                type_validations == 0
                  ? "accepted"
                  : type_validations == 1
                  ? "ecolana_approved"
                  : "declined",
              ],
              per_page: pageSize,
              page: currentPage,
              all: true,
              include_collection_center: true,
              include_recycler: true,
              include_badges: true,
              "include_residue_validation_wastes.wastes": true,
              start_date:
                watch("globalRange.from") !== undefined
                  ? watch("globalRange.from")?.toJSON()
                  : "",
              end_date:
                watch("globalRange.to") !== undefined
                  ? watch("globalRange.to")?.toJSON()
                  : "",
              badge_id: watch("campaign_id") || "",
              type_collection_center: watch("type_collection_center_id") || "",
              search: watch("search") || "",
            },
            token: data?.user?.token ?? undefined,
          })
        : null;
      const data_product: RowCCValidations = getDataToCCValidation(
        results.data
      );
      return { data: data_product, results, meta: { ...results.meta } };
    },
  });

  const { data: totals, refetch: refetchTotals } = useQuery({
    queryKey: ["totals_ticket_grid"],
    enabled: data?.user?.token ? true : false,
    queryFn: async () => {
      return data?.user?.token
        ? await dataServices({
            url: "/residue_validations/overview_totals",
            token: data?.user?.token || "",
          })
        : null;
    },
  });

  const { data: rejectedOptions, refetch: rejectedRefetch } = useQuery<
    RejectedOptionsId[]
  >({
    queryKey: ["rejectedOptions"],
    enabled: data?.user?.token ? true : false,
    queryFn: async () => {
      const results = data?.user?.token
        ? await dataServices({
            url: "/rejected_reasons?type=residue_validation",
            apiVersion: "v2",
            token: data?.user?.token ?? undefined,
          })
        : null;
      return results.data.flatMap((uniWaste: any) => {
        return {
          id: uniWaste.id,
          name: uniWaste.description,
        };
      });
    },
  });

  const { data: type_collection_center } = useQuery<CampaignsOptions[]>({
    queryKey: ["type_collection_center"],
    enabled: data?.user?.token ? true : false,
    queryFn: async () => {
      return [
        { id: "1", name: "Centro de Acopio" },
        { id: "2", name: "Punto Verde" },
        { id: "3", name: "Acopio Exclusivo" },
      ];
    },
  });

  const { data: campaigns } = useQuery<CampaignsOptions[]>({
    queryKey: ["campaigns"],
    queryFn: async () => {
      const results = data?.user?.token
        ? await dataServices({
            url: "/campaigns",
            token: data?.user?.token || "",
            params: { active: true, per_page: 100 },
          })
        : null;
      return results.data.map((item: any) => ({
        id: item.id,
        name: item.name,
      }));
    },
  });

  const { data: badges } = useQuery<CampaignsOptions[]>({
    queryKey: ["badges_cc_validation", data?.user?.token],
    queryFn: async () => {
      if (data?.user?.token) {
        const results = await dataServices({
          url: "/badges",
          params: { badge_type: "especial" },
          apiVersion: "v1",
          token: data?.user?.token,
        });
        return results.data.map((item: any) => ({
          id: item.id,
          name: item.name,
        }));
      } else return null;
    },
  });

  function forceRefetchCCValidation() {
    setCurrentPage(1);
    refetch();
    refetchTotals();
  }

  useEffect(() => {
    if (requestData?.data) {
      setTotalPages(requestData?.meta.total_pages);
      setTotal(requestData?.meta.total_count);
    }
  }, [requestData?.meta]);

  useEffect(() => {
    forceRefetchCCValidation();
  }, [
    watch("globalRange.from"),
    watch("globalRange.to"),
    watch("search"),
    watch("campaign_id"),
    watch("type_collection_center_id"),
    type_validations,
    pageSize,
  ]);

  useEffect(() => {
    if (isFetching && isRefetching) {
      toast({
        title: "Se esta cargando la información",
        description: `Se esta haciendo la busqueda de los datos, por favor espere`,
        variant: "warning",
      });
    }
  }, [isFetching, isRefetching]);

  async function onSubmitValidCCValidation(
    dataCCValidation: collectionCenterValidations
  ) {
    const object_to_update = {
      residue_validation: {
        state: "ecolana_approved",
        badge_id: dataCCValidation.campaign_id,
        residue_validation_wastes_attributes:
          dataCCValidation.wastes_validations.map((uni_validation) => ({
            id: uni_validation.id_waste_valdation,
            waste_id: uni_validation.waste_id,
            quantity: uni_validation.quantity,
            _destroy: uni_validation._destroy,
          })),
      },
    };
    await dataServices({
      url: `/residue_validations/${dataCCValidation.id_cc_validation}`,
      requestType: "PATCH",
      body: object_to_update,
      contentType: "multipart/form-data",
      apiVersion: "v2",
      token: data?.user?.token ?? undefined,
    });
    forceRefetchCCValidation();
  }

  async function onSubmitRejectedCCValidation(
    dataRejected: RejectedValidation,
    dataCCValidation: collectionCenterValidations
  ) {
    const object_to_update = {
      residue_validation: {
        state: "declined",
        rejected_reason: dataRejected.rejected_category,
        residue_validation_wastes_attributes:
          dataCCValidation.wastes_validations.map((uni_validation) => ({
            id: uni_validation.id_waste_valdation,
            waste_id: uni_validation.waste_id,
            quantity: uni_validation.quantity,
            _destroy: uni_validation._destroy,
          })),
      },
    };
    await dataServices({
      url: `/residue_validations/${dataCCValidation.id_cc_validation}`,
      requestType: "PATCH",
      body: object_to_update,
      contentType: "multipart/form-data",
      apiVersion: "v2",
      token: data?.user?.token ?? undefined,
    });
    forceRefetchCCValidation();
  }

  async function onSubmitNewRejectedOption(dataRejected: RejectedValidation) {
    const object_to_update = {
      rejected_reason: {
        description: dataRejected.new_rejected_category,
        rejected_reason_type: "residue_validation",
      },
    };
    const formData = serialize(object_to_update);
    await dataServices({
      url: `/rejected_reasons`,
      apiVersion: "v2",
      requestType: "POST",
      body: formData,
      contentType: "multipart/form-data",
      token: data?.user?.token ?? undefined,
    });
    rejectedRefetch();
  }

  async function onSubmitDeleteCCValidation(
    dataCCValidation: collectionCenterValidations
  ) {
    await dataServices({
      url: `/residue_validations/${dataCCValidation.id_cc_validation}`,
      requestType: "DELETE",
      contentType: "multipart/form-data",
      apiVersion: "v2",
      token: data?.user?.token ?? undefined,
    });
    forceRefetchCCValidation();
  }

  return {
    totals,
    requestData,
    control_date: control,
    pagination: {
      currentPage,
      firstPage,
      lastPage,
      nextPage,
      prevPage,
      paginationLabel,
      last_page,
      onChangePageSize,
      pageSize,
    },
    rejectedOptions: rejectedOptions ?? [],
    type_collection_center: type_collection_center ?? [],
    campaigns: campaigns ?? [],
    badges: badges ?? [],
    wastes,
    wastes_categories,
    onSubmitValidCCValidation,
    onSubmitRejectedCCValidation,
    onSubmitDeleteCCValidation,
    onSubmitNewRejectedOption,
  };
};

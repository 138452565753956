// Generated by https://quicktype.io

export interface Campaign {
    id:                 string;
    type:               string;
    collection_centers: Badges;
    badges:             Badges;
    recyclers:          Badges;
    companies:          Badges;
    campaign_term:      CampaignTerm;
    name:               string;
    description:        string;
    campaign_type:      string;
    start_date:         string;
    end_date:           string;
    active:             boolean;
    image:              string;
    pin:                null;
  }
  
  export interface Badges {
    data: DAT[];
  }
  
  export interface DAT {
    id:   string;
    type: Type;
  }
  
  export enum Type {
    Badge = "badge",
    CampaignTerm = "campaign_term",
    CollectionCenter = "collection_center",
    Company = "company",
  }
  
  export interface CampaignTerm {
    data: DAT;
  }
  
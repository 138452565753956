import { useForm } from "react-hook-form";
import { DateRange } from "react-day-picker";
import { useEffect } from "react";

export interface FormFilterGraph {
  globalRange: DateRange;
  graphsRanges: { [key: string]: DateRange };
  graphsYear: { [key: string]: string };
  filterMonthWeek: { [key: string]: string };
}
interface UseGraphicsDataProps {
  keysRanges: string[];
  keysYear: string[];
  keysMonthWeek?: string[];
}
export const useGraphicsData = ({
  keysRanges,
  keysYear,
}: UseGraphicsDataProps) => {
  const rangesDefaultValues = keysRanges.reduce((acc, key) => {
    return { ...acc, [key]: { from: undefined, to: undefined } };
  }, {});
  const yearDefaultValues = keysYear.reduce((acc, key) => {
    return { ...acc, [key]: "" };
  }, {});
  const { control, reset, watch, setValue } = useForm<FormFilterGraph>({
    defaultValues: {
      globalRange: {
        from: undefined,
        to: undefined,
      },
      graphsRanges: rangesDefaultValues,
      graphsYear: yearDefaultValues,
    },
  });

  const ranges = watch("graphsRanges");
  const years = watch("graphsYear");
  const global = watch("globalRange");
  const filterMonthWeek = watch("filterMonthWeek");

  return {
    control,
    reset,
    watch,
    setValue,
    ranges,
    years,
    global,
  };
};

import { ConvertData, ReponseData } from "@/interfaces";
import { DateRange } from "react-day-picker";

export function formatData(data: ReponseData[]) {
  return data.reduce((acc: any, item: ReponseData) => {
    if (item.graph === "history") {
      return acc;
    }
    if (item.range && item.range !== null) {
      const groupedData: { [key: string]: number[] } = {};
      const ranges = data
        .filter((row) => row.graph === item.graph)
        .reduce((acc: any, row: any) => {
          if (!acc.includes(row.range)) {
            acc.push(row.range);
          }
          return acc;
        }, []);
      const labels = data
        .filter((row) => row.graph === item.graph)
        .reduce((acc: any, row: any) => {
          if (!acc.includes(row.label)) {
            acc.push(row.label);
          }
          return acc;
        }, []);

      ranges.forEach((range: string) => {
        if (!groupedData[range]) {
          groupedData[range] = new Array(labels.length).fill(0);
        }
      });
      ranges.forEach((range: string) => {
        data
          .filter((row) => row.graph === item.graph && row.range === range)
          .forEach((row: any) => {
            groupedData[row.range][labels.indexOf(row.label)] = row.value;
          });
      });

      const values = Object.keys(groupedData).map((key) => ({
        label: key,
        value: groupedData[key],
      }));

      const topValues = values.sort((a, b) => {
        const aTotal = a.value.reduce((acc, value) => acc + value, 0);
        const bTotal = b.value.reduce((acc, value) => acc + value, 0);
        return bTotal - aTotal;
      });
      return {
        ...acc,
        [item.graph]: {
          label: labels,
          value: topValues,
        },
      };
    } else {
      acc = {
        ...acc,
        [item.graph]: [
          ...(acc[item.graph] || []),
          {
            label: item.label,
            value: item.value,
          },
        ],
      };
      return acc;
    }
  }, {});
}

export function formatDataComplex(
  data: ReponseData[],
  key: string
): ConvertData {
  const groupedData: { [key: string]: number[] } = {};
  data.forEach((row: any) => {
    if (!groupedData[row.type_data]) {
      groupedData[row.type_data] = new Array(12).fill(0);
    }
  });
  data.forEach((row: any) => {
    groupedData[row.type_data][row.month - 1] = row.count_data ?? 0;
  });
  return {
    [key]: Object.keys(groupedData).map((key) => ({
      label: key,
      value: groupedData[key],
    })),
  };
}

export function formatDataComplexNew(
  data: ReponseData[],
  key: string
): ConvertData {
  const groupedData: { [key: string]: number[] } = {};
  data
    .filter((row) => row.graph === key)
    .forEach((row) => {
      if (!groupedData[row.label]) {
        groupedData[row.label] = new Array(12).fill(0);
      }
    });
  data
    .filter((row) => row.graph === key)
    .forEach((row) => {
      let value = Number(row.range);
      groupedData[row.label][value - 1] = (row.value as number) ?? 0;
    });
  return {
    [key]: Object.keys(groupedData).map((key) => ({
      label: key,
      value: groupedData[key],
    })),
  };
}

export function formatDataHorizontalComplex(
  data: ReponseData[],
  key: string
): ConvertData {
  const groupedData: { [key: string]: number[] } = {};
  data.forEach((row: any) => {
    if (!groupedData[row.type_data]) {
      groupedData[row.type_data] = new Array(12).fill(0);
    }
  });
  data.forEach((row: any) => {
    groupedData[row.type_data][row.month - 1] = row.count_data ?? 0;
  });
  return {
    [key]: Object.keys(groupedData).map((key) => ({
      label: key,
      value: groupedData[key],
    })),
  };
}

export function formatNumber(number?: string) {
  return number ? Number(number).toFixed(0).toString() : "0";
}

export function formatParamsForFilters(
  ranges: { [key: string]: DateRange },
  global: DateRange,
  years: { [key: string]: string }
) {
  try {
    const localRanges = {} as { [key: string]: string };
    Object.keys(ranges).forEach((key) => {
      if (global.from !== undefined && global.to !== undefined) {
        localRanges[`start_date_${key}`] = global.from!.toJSON();
        localRanges[`end_date_${key}`] = global.to!.toJSON();
      } else if (ranges[key]!== undefined && ranges[key].from && ranges[key].to) {
        localRanges[`start_date_${key}`] =
          ranges[key].from !== undefined
            ? ranges[key].from!.toJSON()
            : global.from!.toJSON();
        localRanges[`end_date_${key}`] =
          ranges[key].to !== undefined
            ? ranges[key].to!.toJSON()
            : global.to!.toJSON();
      }
      // console.log({ global, rango: ranges[key], key });
    });
    const localYears = {} as { [key: string]: string };
    Object.keys(years).forEach((key) => {
      if (years[key] !== "" || (global.from && global.to)) {
        localYears[key] =
          years[key] !== ""
            ? years[key]
            : global.from !== undefined && global.to !== undefined
            ? global.from!.getFullYear().toString()
            : "";
      }
    });
    return { localRanges, localYears };
  } catch (error) {
    // console.error(error);
    return { localRanges: {}, localYears: {} };
  }
}

import { CampaignsOptions } from "@/constants/campaignsData";
import { api_v1 } from "../api";
import { Campaign } from "@/interfaces/campaignsInterface";

export async function getActiveCampaigns() {
  // const active_campaigns: CampaignsOptions[] = Campaigns.filter(
  //   (uni_campaign: CampaignProps) => uni_campaign.active
  // ).map((uni_campaign: CampaignProps) => {
  //   return { id: uni_campaign.id, name: uni_campaign.name };
  // });
  const resp: { data: Campaign[] } = await api_v1.get(
    "/campaigns?campaign_type[]=0&campaign_type[]=2"
  );
  const active: CampaignsOptions[] = resp.data
    .filter((uni_campaign) => uni_campaign.active)
    .map((uni_campaign: Campaign) => {
      return { id: uni_campaign.id, name: uni_campaign.name };
    });
  return active;
}

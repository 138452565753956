import { toast, useToast } from "@/components/atoms/shadcn/ui/use-toast";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { useEffect } from "react";
import { DateRange } from "react-day-picker";

export function useCheckRefetch(
  refetch: () => void,
  global: DateRange,
  ranges?: { [key: string]: DateRange },
  years?: { [key: string]: string },
  refetchTotals?: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<any, Error>>
) {
  useEffect(() => {
    if (
      (global && global.from && global.to) ||
      (ranges &&
        Object.values(ranges).some(
          (value) => value?.from !== undefined && value?.to !== undefined
        )) ||
      (years &&
        years &&
        Object.values(years).some(
          (value) => value !== undefined && value !== ""
        ))
    ) {
      refetch();
      refetchTotals && refetchTotals();
      toast({
        title: "Se ha solicitado la información",
        description: `Espera un momento en lo que la información es actualizada`,
        variant: "warning",
      });
    }
  }, [
    global,
    ranges && Object.keys(ranges).values(),
    years && Object.keys(years).values(),
  ]);
}

import { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { ProductWasteSchema, Sku } from "@/interfaces/skuInterface";
import { Row } from "@tanstack/react-table";
import { product_wastes } from "../interfaces/skuInterface";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSession } from "next-auth/react";
import { dataFormProdctWastes } from "@/shared/functions/dataFormProdctWastes";
import { dataServices } from "./dataServices";
import { serialize } from "object-to-formdata";
import { useQuery } from "@tanstack/react-query";
import { CompanyNew, Company } from "@/interfaces";
import { useWastesData } from "./useWastesData";

const SkuSchema = z.object({
  id: z.string(),
  id_product_waste: z.string().optional(),
  company: z
    .string({ required_error: "Empresa es requerida" })
    .min(1, "Selecciona una Empresa")
    .optional(),
  company_name: z.string().optional(),
  brand: z
    .string({ required_error: "Marca es requerida" })
    .min(1, "Marca es requerida"),
  brand_name: z.string().optional(),
  sub_brand: z
    .string({ required_error: "Submarca es requerida" })
    .min(1, "Submarca es requerida"),
  sub_brand_name: z.string().optional(),
  sku: z.string().min(2, "SKU más largo"),
  measure: z.string().min(2, "Medida más larga"),
  measure_name: z.string().optional(),
  type_measure: z
    .string({ required_error: "Tipo de medida es requerida" })
    .min(1, "Tipo de medida es requerida"),
  type_measure_name: z.string().optional(),
  waste_id: z.string().optional(),
  waste_name: z.string().optional(),
  waste_category_id: z.string().optional(),
  waste_category_name: z.string().optional(),
  product_wastes: z.array(ProductWasteSchema).min(1),
  image: z.string().optional(),
  file: z.any().optional(),
});

const NewCompanySchema = z.object({
  file: z.any(),
  company_name: z.string(),
});
interface useEditableRowProps {
  initial_sku?: Sku;
  isEditing?: boolean;
  isDuplicated?: boolean;
  isAddNewSKU?: boolean;
  refetch?: () => void;
}

export const useEditableRow = ({
  initial_sku = {
    id: undefined,
    company: "",
    brand: "",
    sub_brand: "",
    sku: "",
    measure: "",
    type_measure: "",
    product_wastes: [],
  },
  isEditing = false,
  isDuplicated = false,
  isAddNewSKU = false,
  refetch,
}: useEditableRowProps) => {
  const { data } = useSession();

  const { type_measure, wastes, wastes_categories } = useWastesData(data);
  const {
    control: control_new_company,
    handleSubmit: handleSubmit_new_company,
    setValue: setValue_new_company,
    reset: reset_new_company,
    formState: { errors: errors_new_company },
  } = useForm<CompanyNew>({
    resolver: zodResolver(NewCompanySchema),
  });

  const { data: companys, refetch: refetch_company } = useQuery<Company[]>({
    queryKey: ["companys"],
    enabled: data?.user?.token ? true : false,
    queryFn: async () => {
      const results = data?.user?.token
        ? await dataServices({
            url: "/companies?per_page=100000&page=1",
            apiVersion: "v1",
            token: data?.user?.token ?? undefined,
          })
        : null;
      return [
        ...results.data.flatMap((uniWaste: any) => {
          return {
            id: uniWaste.id,
            name: uniWaste.name,
          };
        }),
        {
          id: `other`,
          name: "Otra",
        },
      ];
    },
  });

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    getValues,
    formState: { errors: errors_sku },
  } = useForm<Sku>({
    resolver: zodResolver(SkuSchema),
    defaultValues: initial_sku,
  });
  const { fields, append, prepend, remove, update } = useFieldArray({
    name: "product_wastes",
    control,
  });
  const watch_company = watch("company");
  const [isOpen, setIsOpen] = useState(false);
  const [isEditable, setIsEditable] = useState<boolean>(isEditing);
  const [isDuplicate, setIsDuplicate] = useState<boolean>(isDuplicated);
  const [idxEdit, setIndxEdit] = useState("");
  const [isOpenDialogImages, setIsOpenDialogImages] = useState(false);
  const [isOpenDialogCompany, setIsOpenDialogCompany] = useState(false);
  const [isAddNew, setIsAddNew] = useState(isAddNewSKU);
  function resetData(row: Row<Sku>) {
    reset();
    remove();
    row.toggleSelected(!row.getIsSelected());
    setValue("id", row.original.id);
    setValue("company", row.original.company);
    setValue("company_name", row.original.company_name);
    setValue("brand", row.original.brand);
    setValue("brand_name", row.original.brand_name);
    setValue("sub_brand", row.original.sub_brand);
    setValue("sub_brand_name", row.original.sub_brand_name);
    setValue("sku", row.original.sku);
    setValue("measure", row.original.measure);
    setValue("type_measure", row.original.type_measure);
    setValue("type_measure_name", row.original.type_measure_name);
    setValue("image", row.original.image);
    setValue("id_product_wastes", row.original.id_product_wastes);
    setValue("waste_id", row.original.waste_id);
    setValue("waste_name", row.original.waste_name);
    setValue("waste_category_id", row.original.waste_category_id);
    setValue("waste_category_name", row.original.waste_category_name);
    row.original.product_wastes.forEach((_, idx) => {
      append({
        id_product_wastes: row.original.product_wastes[idx].id_product_wastes,
        waste_id: row.original.product_wastes[idx].waste_id,
        waste_name: row.original.product_wastes[idx].waste_name,
        waste_category_id: row.original.product_wastes[idx].waste_category_id,
        waste_category_name:
          row.original.product_wastes[idx].waste_category_name,
        image: row.original.product_wastes[idx].image,
        file: undefined,
        _destroy: row.original.product_wastes[idx]._destroy,
      });
    });
  }
  function handleCloseDialog(idx?: string) {
    setIsOpen(!isOpen);
    idx ? setIndxEdit(idx) : undefined;
  }
  function handleEditable() {
    setIsEditable(!isEditable);
  }
  function handleEditRow(row: Row<Sku>) {
    resetData(row);
    setIsEditable(true);
    setIsDuplicate(false);
  }
  function handleDeleteRow(idx: number, handleOpenDialog: () => void) {
    remove(idx);
    handleOpenDialog();
  }
  function handleDuplicateRow(row: Row<Sku>) {
    resetData(row);
    append({
      waste_id: "",
      waste_category_id: "",
      image: "",
      file: undefined,
      _destroy: false,
    });
    setIsEditable(true);
    setIsDuplicate(true);
  }

  function handleAddProductWaste() {
    append({
      waste_id: "",
      waste_category_id: "",
      image: "",
      _destroy: false,
    });
  }

  function handleCancelEdit(row?: Row<Sku>) {
    reset();
    remove();
    row?.toggleSelected(!row?.getIsSelected());
    setIsEditable(false);
    setIsDuplicate(false);
  }
  function handleViewImages(row: Row<Sku>) {
    reset();
    remove();
    row.toggleSelected(!row.getIsSelected());
    resetData(row);
    setIsOpenDialogImages(!isOpenDialogImages);
  }

  function handleSetNewImages() {
    setIsOpenDialogImages(!isOpenDialogImages);
  }
  function handleCloseDialogImages() {
    setIsOpenDialogImages(!isOpenDialogImages);
  }
  function handleAddNewSKU() {
    reset();
    append({
      waste_id: "",
      waste_category_id: "",
      image: "",
      file: undefined,
      _destroy: false,
    });
    setIsAddNew(true);
  }
  function handleCancelNew() {
    reset();
    remove();
    reset_new_company();
    setIsAddNew(false);
  }
  function handleAddNewProductWaste() {
    append({
      waste_category_id: "",
      waste_id: "",
      file: undefined,
      _destroy: false,
    });
  }

  function forceRefetchSKU() {
    refetch ? refetch() : null;
    setIsEditable(false);
    setIsDuplicate(false);
    setIsAddNew(false);
    reset();
    remove();
  }

  async function handleSaveImageSKU() {
    const dataSKU = getValues();
    if (dataSKU.id && dataSKU.id !== "") {
      const object_to_update = {
        product: {
          image: dataSKU.file,
          product_wastes_attributes: dataFormProdctWastes(
            dataSKU.product_wastes
          ),
        },
      };
      const formData = serialize(object_to_update);
      const response = await dataServices({
        url: `/products/${dataSKU.id}`,
        requestType: "PATCH",
        body: formData,
        contentType: "multipart/form-data",
        token: data?.user?.token ?? undefined,
      });
    } else {
      const object_to_update = {
        product: {
          image: dataSKU.file ? dataSKU.file : undefined,
          enterprise_name: companys?.find((item) => item.id === dataSKU.company)
            ?.name,
          marca: dataSKU.brand,
          submarca: dataSKU.sub_brand,
          sku: dataSKU.sku,
          quantity: dataSKU.measure,
          measure_unit_id: dataSKU.type_measure,
          product_wastes_attributes: dataFormProdctWastes(
            dataSKU.product_wastes
          ),
        },
      };
      const formData = serialize(object_to_update);
      const response = await dataServices({
        url: `/products`,
        requestType: "POST",
        body: formData,
        contentType: "multipart/form-data",
        token: data?.user?.token ?? undefined,
      });
    }
    setIsOpenDialogImages(!isOpenDialogImages);
    forceRefetchSKU();
  }

  useEffect(() => {
    if (watch_company == "other") {
      setIsOpenDialogCompany(true);
    }
  }, [watch_company]);

  async function handleNewCompany(dataCompanyNew: CompanyNew) {
    const object_to_update = {
      company: {
        logo: dataCompanyNew.file,
        name: dataCompanyNew.company_name,
      },
    };
    const formData = serialize(object_to_update);
    const response = await dataServices({
      url: "/companies",
      token: data?.user?.token ?? undefined,
      requestType: "POST",
      body: formData,
      contentType: "multipart/form-data",
    });
    refetch_company();
    setIsOpenDialogCompany(!isOpenDialogCompany);
    reset_new_company();
  }

  function handleOpenDialogCompany() {
    setIsOpenDialogCompany(!isOpenDialogCompany);
    reset_new_company();
  }

  async function handleSaveNewRow(dataSKU: Sku) {
    try {
      const object_to_update = {
        product: {
          enterprise_name: companys?.find((item) => item.id === dataSKU.company)
            ?.name,
          marca: dataSKU.brand,
          submarca: dataSKU.sub_brand,
          sku: dataSKU.sku,
          quantity: dataSKU.measure,
          measure_unit_id: dataSKU.type_measure,
          product_wastes_attributes: dataFormProdctWastes(
            dataSKU.product_wastes
          ),
        },
      };
      const formData = serialize(object_to_update);
      const response = await dataServices({
        url: `/products`,
        requestType: "POST",
        body: formData,
        contentType: "multipart/form-data",
        token: data?.user?.token ?? undefined,
      });
      forceRefetchSKU();
    } catch (error) {
      console.log(error);
    }
  }

  async function handleDeleteSKU() {
    const resp = await dataServices({
      url: `/products/${idxEdit}`,
      requestType: "DELETE",
      token: data?.user?.token ?? undefined,
    });
    handleCloseDialog();
    forceRefetchSKU();
  }

  async function handleSaveRow(dataSKU: Sku) {
    const object_to_update = {
      product: {
        enterprise_name: dataSKU.company_name,
        marca: dataSKU.brand,
        submarca: dataSKU.sub_brand,
        sku: dataSKU.sku,
        quantity: dataSKU.measure,
        measure_unit_id: dataSKU.type_measure,
        product_wastes_attributes: dataFormProdctWastes(dataSKU.product_wastes),
      },
    };
    const formData = serialize(object_to_update);
    const response = await dataServices({
      url: `/products/${dataSKU.id}`,
      requestType: "PATCH",
      body: formData,
      contentType: "multipart/form-data",
      token: data?.user?.token ?? undefined,
    });
    forceRefetchSKU();
  }

  return {
    control,
    errors_sku,
    handleSubmit,
    fields,
    append,
    prepend,
    remove,
    isEditable,
    isDuplicate,
    handleEditable,
    handleEditRow,
    handleDuplicateRow,
    handleSaveRow,
    handleDeleteRow,
    handleCancelEdit,
    idxEdit,
    handleCloseDialog,
    isOpen,
    handleViewImages,
    isOpenDialogImages,
    handleCloseDialogImages,
    handleAddNewSKU,
    handleCancelNew,
    isAddNew,
    isOpenDialogCompany,
    handleNewCompany,
    companys: companys ?? [],
    type_measure,
    wastes,
    wastes_categories,
    control_new_company,
    handleSubmit_new_company,
    errors_new_company,
    setValue_new_company,
    handleSaveNewRow,
    handleDeleteSKU,
    handleSetNewImages,
    handleSaveImageSKU,
    handleAddProductWaste,
    handleOpenDialogCompany,
    watch,
    handleAddNewProductWaste,
  };
};

import { useSession } from "next-auth/react";
import { usePagination } from "./usePagination";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import {
  GridProduct,
  Product,
  ProductResponse,
  ProductWastesDatum,
  RejectedOptionsId,
  RejectedValidation,
  RowProduct,
  product_wastes,
} from "@/interfaces";
import { dataServices } from "./dataServices";
import { serialize } from "object-to-formdata";
import { dataFormProdctWastes } from "@/shared/functions/dataFormProdctWastes";
import { DateRange } from "react-day-picker";
import { useForm } from "react-hook-form";
import { useWastesData } from "./useWastesData";

export function transform2Grid(arr: RowProduct): GridProduct {
  const result: any[][] = [];
  const chunkSize = 3;
  for (let i = 0; i < arr.length; i += chunkSize) {
    result.push(arr.slice(i, i + chunkSize));
  }
  return result;
}

function getDataToProduct(arr: ProductResponse[]) {
  return arr.flatMap((dataTest: ProductResponse): Product => {
    return {
      id_user: dataTest.recycler.data.id,
      user_name:
        dataTest.recycler.data.first_name && dataTest.recycler.data.last_name
          ? `${dataTest.recycler.data.first_name} ${dataTest.recycler.data.last_name}`
          : undefined,
      id_product: dataTest.product.data.id,
      id_product_registrations: dataTest.id,
      sku: dataTest.product.data.sku,
      date_register: new Date(dataTest.created_at).toLocaleDateString("es-MX"),
      company_name: dataTest.product.data.enterprise_name ?? undefined,
      brand_name: dataTest.product.data.marca ?? undefined,
      sub_brand_name: dataTest.product.data.submarca ?? undefined,
      measure: dataTest.product.data.quantity ?? undefined,
      type_measure: dataTest.product.data.measure_unit.data.id,
      type_measure_name:
        dataTest.product.data.measure_unit.data.name ?? undefined,
      waste_name:
        dataTest.product.data.product_wastes.data[0]?.waste?.data?.name ??
        undefined,
      waste_id:
        dataTest.product.data.product_wastes.data[0]?.waste.data?.id ??
        undefined,
      waste_category_name:
        dataTest.product.data.product_wastes.data[0]?.waste_category?.data
          ?.name ?? undefined,
      waste_category_id:
        dataTest.product.data.product_wastes.data[0]?.waste_category.data?.id ??
        undefined,
      id_product_wastes:
        dataTest.product.data.product_wastes.data[0]?.id ?? undefined,
      product_wastes:
        dataTest.product.data.product_wastes?.data.length > 0
          ? dataTest.product.data.product_wastes.data.map(
              (item: ProductWastesDatum): product_wastes => {
                return {
                  waste_id: item?.waste?.data?.id ?? undefined,
                  waste_name: item?.waste?.data?.name ?? undefined,
                  waste_category_id:
                    item?.waste_category?.data?.id ?? undefined,
                  waste_category_name:
                    item?.waste_category?.data?.name ?? undefined,
                  _destroy: false,
                  id_product_wastes: item?.id,
                  image: item?.photo ?? undefined,
                };
              }
            )
          : [],
      image: dataTest.product.data.image ?? undefined,
      description: dataTest.product.data.description ?? undefined,
    };
  });
}

export const useDataProduct = (
  per_page: number,
  showGrid: boolean,
  type_validations: number
) => {
  const { data } = useSession();
  const { control, watch } = useForm<{
    globalRange: DateRange;
    search: string;
  }>({
    defaultValues: {
      globalRange: {
        from: undefined,
        to: undefined,
      },
      search: "",
    },
  });

  const {
    currentPage,
    firstPage,
    lastPage,
    nextPage,
    prevPage,
    last_page,
    paginationLabel,
    setTotal,
    setTotalPages,
    setCurrentPage,
    pageSize,
    onChangePageSize,
  } = usePagination(per_page);

  const { type_measure, wastes, wastes_categories } = useWastesData(data);

  const { data: requestData, refetch } = useQuery({
    queryKey: [
      `product_registrations`,
      currentPage,
      type_validations,
      pageSize,
    ],
    enabled: data?.user?.token ? true : false,
    queryFn: async () => {
      const results = data?.user?.token
        ? await dataServices({
            url: "/product_registrations",
            params: {
              status:
                type_validations == 0
                  ? "pending"
                  : type_validations == 1
                  ? "accepted"
                  : "rejected",
              admin: true,
              per_page: pageSize,
              page: currentPage,
              "include_product.product_wastes.waste": "true",
              "include_product.product_wastes.waste_category": "true",
              "include_product.measure_unit": "true",
              include_recycler: true,
              start_date:
                watch("globalRange.from") !== undefined
                  ? watch("globalRange.from")?.toJSON()
                  : "",
              end_date:
                watch("globalRange.to") !== undefined
                  ? watch("globalRange.to")?.toJSON()
                  : "",
              search: watch("search") || "",
            },
            token: data?.user?.token ?? undefined,
          })
        : null;
      const data_product: Product[] = getDataToProduct(results.data);
      return { data: data_product, results, meta: { ...results.meta } };
    },
  });

  const { data: totals, refetch: refetchTotals } = useQuery({
    queryKey: ["totals_product_registrations"],
    enabled: data?.user?.token ? true : false,
    queryFn: async () => {
      return data?.user?.token
        ? await dataServices({
            url: "/product_registrations/overview_totals",
            token: data?.user?.token || "",
          })
        : null;
    },
  });

  const { data: rejectedOptions, refetch: rejectedRefetch } = useQuery<
    RejectedOptionsId[]
  >({
    queryKey: ["rejectedOptions"],
    enabled: data?.user?.token ? true : false,
    queryFn: async () => {
      const results = data?.user?.token
        ? await dataServices({
            url: "/rejected_reasons?type=product_registration",
            apiVersion: "v2",
            token: data?.user?.token ?? undefined,
          })
        : null;
      return results.data.flatMap((uniWaste: any) => {
        return {
          id: uniWaste.id,
          name: uniWaste.description,
        };
      });
    },
  });

  useEffect(() => {
    if (requestData) {
      setTotalPages(requestData.meta.total_pages);
      setTotal(requestData.meta.total_count);
    }
  }, [requestData]);

  function forceRefetchProducts() {
    setCurrentPage(1);
    refetch();
    refetchTotals();
  }

  useEffect(() => {
    forceRefetchProducts();
  }, [
    watch("globalRange.from"),
    watch("globalRange.to"),
    watch("search"),
    type_validations,
    pageSize,
  ]);

  async function onSubmitValidProduct(dataProduct: Product) {
    const object_to_update = {
      product: {
        enterprise_name: dataProduct.company_name,
        marca: dataProduct.brand_name,
        submarca: dataProduct.sub_brand_name,
        sku: dataProduct.sku,
        quantity: dataProduct.measure,
        measure_unit_id: dataProduct.type_measure,
        product_registrations_attributes: [
          {
            state: "accepted",
            comment: "",
            id: dataProduct.id_product_registrations,
          },
        ],
        product_wastes_attributes: dataFormProdctWastes(
          dataProduct.product_wastes
        ),
      },
    };
    const formData = serialize(object_to_update);
    await dataServices({
      url: `/products/${dataProduct.id_product}`,
      requestType: "PATCH",
      body: formData,
      contentType: "multipart/form-data",
      token: data?.user?.token ?? undefined,
    });
    forceRefetchProducts();
  }

  async function onSubmitRejectedProduct(
    dataRejected: RejectedValidation,
    dataProduct: Product
  ) {
    const object_to_update = {
      product: {
        enterprise_name: dataProduct.company_name,
        marca: dataProduct.brand_name,
        submarca: dataProduct.sub_brand_name,
        sku: dataProduct.sku,
        quantity: dataProduct.measure,
        measure_unit_id: dataProduct.type_measure,
        product_registrations_attributes: [
          {
            state: "rejected",
            id: dataProduct.id_product_registrations,
            rejected_reason_id: dataRejected.rejected_category,
          },
        ],
        product_wastes_attributes: dataFormProdctWastes(
          dataProduct.product_wastes
        ),
      },
    };
    const formData = serialize(object_to_update);
    await dataServices({
      url: `/products/${dataProduct.id_product}`,
      requestType: "PATCH",
      body: formData,
      contentType: "multipart/form-data",
      token: data?.user?.token ?? undefined,
    });
    forceRefetchProducts();
  }

  async function onSubmitNewRejectedOption(dataRejected: RejectedValidation) {
    const object_to_update = {
      rejected_reason: {
        description: dataRejected.new_rejected_category,
        rejected_reason_type: "product_registration",
      },
    };
    const formData = serialize(object_to_update);
    await dataServices({
      url: `/rejected_reasons`,
      requestType: "POST",
      body: formData,
      contentType: "multipart/form-data",
      token: data?.user?.token ?? undefined,
    });
    rejectedRefetch();
  }
  async function onSubmitDeleteProduct(dataProduct: Product) {
    await dataServices({
      url: `/products/${dataProduct.id_product}`,
      requestType: "DELETE",
      token: data?.user?.token ?? undefined,
    });
    forceRefetchProducts();
  }

  return {
    requestData,
    totals,
    control_date: control,
    pagination: {
      currentPage,
      firstPage,
      lastPage,
      nextPage,
      prevPage,
      paginationLabel,
      last_page,
      onChangePageSize,
      pageSize,
    },
    wastes,
    type_measure,
    wastes_categories,
    rejectedOptions: rejectedOptions || [],
    onSubmitDeleteProduct,
    onSubmitRejectedProduct,
    onSubmitNewRejectedOption,
    onSubmitValidProduct,
  };
};

export const app_home_url = "https://ecolana.page.link/descarga_home";
export const fb_url = "https://www.facebook.com/ecolanamx/";
export const insta_url = "https://www.instagram.com/ecolanamx/";
export const twitter_url = "https://twitter.com/ecolanamx";
export const tiktok_url = "https://www.tiktok.com/@ecolanamx?lang=es";
export const youtube_url =
  "https://www.youtube.com/channel/UCrnbkmXWF9hVnAqJ1gAftbA";
export const baseURL = process.env.NEXT_PUBLIC_BASE_URL;
export const apiBaseURL = process.env.NEXT_PUBLIC_MAIN_API_URL + "/v2";
export const apiBaseURLV1 = process.env.NEXT_PUBLIC_MAIN_API_URL + "/v1";
export const CDN_URL = process.env.NEXT_PUBLIC_CDN_URL;

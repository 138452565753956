import { useQuery } from "@tanstack/react-query";
import { dataServices } from "./dataServices";
import { usePagination } from "./usePagination";
import {
  CCInventory,
  CC_Form,
  CollectionCenterResponse,
  RowCCInventory,
} from "@/interfaces";
import { useForm } from "react-hook-form";
import { useSession } from "next-auth/react";
import { useEffect } from "react";
import { DateRange } from "react-day-picker";
import { serialize } from "object-to-formdata";
import { Session } from "next-auth";
import { useToast } from "@/components/atoms/shadcn/ui/use-toast";
function getDataToCCInventory(arr: any, data: Session | null) {
  const auxCCData: CCInventory[] = arr.flatMap(
    (dataTest: CollectionCenterResponse) => {
      return {
        campaigns:
          dataTest.campaign_collection_centers.data.map((item) => ({
            id: item.id,
            campaign_id: item.campaign?.data.id,
            name: item?.campaign?.data?.name,
            _destroy: false,
          })) ?? [],
        id: dataTest.id ?? undefined,
        benefit: {
          id: dataTest?.collection_center_benefit?.data?.id ?? undefined,
          name: dataTest?.collection_center_benefit?.data?.name ?? undefined,
        },
        wastes:
          dataTest?.collection_center_wastes?.data.map((item) => ({
            waste_id: item.waste?.data?.id,
            waste_name: item.waste?.data?.name,
            id_waste_valdation: item.id,
            _destroy: false,
          })) ?? [],
        active: dataTest.active ?? undefined,
        name: dataTest.name ?? undefined,
        stars: dataTest?.score ? Number(dataTest?.score) : undefined,
        type_cc_name:
          [
            { id: "centro_acopio", name: "Centro de Acopio" },
            { id: "punto_verde", name: "Punto Verde" },
            { id: "acopio_exclusivo", name: "Acopio Exclusivo" },
          ].find((uni_item) => dataTest.center_type == uni_item.id)?.name ??
          undefined,
        contact:
          dataTest.collection_center_contacts.data.length > 0
            ? {
                id:
                  dataTest.collection_center_contacts.data[0]?.id ?? undefined,
                id_contact:
                  dataTest.collection_center_contacts.data[0]?.contact?.data
                    ?.id ?? undefined,
                phone:
                  dataTest.collection_center_contacts.data[0]?.contact?.data
                    ?.phone ?? undefined,
                phone_ext:
                  dataTest.collection_center_contacts.data[0].contact?.data
                    ?.phone_ext ?? undefined,
              }
            : {},
        type_cc_id: dataTest?.center_type ?? undefined,
        ticket: dataTest?.ticket ?? undefined,
        isCollector: dataTest?.collector ?? false,
        state_name: dataTest.address.data.text_address ?? undefined,
        schedule_id: dataTest?.schedule_model?.data?.id ?? undefined,
        address: {
          id: dataTest?.address?.data?.id ?? undefined,
          street: dataTest?.address?.data.street ?? undefined,
          exterior: dataTest?.address?.data?.exterior ?? undefined,
          interior: dataTest?.address?.data?.interior ?? undefined,
          lng: dataTest?.address?.data?.lng ?? undefined,
          lat: dataTest?.address?.data?.lat ?? undefined,
          state_id: undefined,
          town_id: undefined,
          zipcode_id: undefined,
          settlement_id:
            dataTest?.address?.data?.settlement?.data?.id ?? undefined,
        },
        comments: dataTest.comments.data.map((uniComment) => ({
          id: uniComment.id ?? undefined,
          comment: uniComment.description ?? undefined,
          score: uniComment.score ?? undefined,
          created_at:
            new Date(uniComment.created_at).toLocaleDateString("es-MX") ??
            undefined,
        })),
        socials:
          dataTest.collection_center_socials.data.map((uni_social) => ({
            id: uni_social.id ?? undefined,
            social_medium_attributes: {
              id: uni_social.social_medium?.data?.id ?? undefined,
              site: uni_social.social_medium?.data?.site ?? undefined,
              url: uni_social.social_medium?.data?.url ?? undefined,
            },
          })) ?? [],
        nup: {
          id: dataTest?.collection_center_nup?.data?.id ?? undefined,
          nup_id:
            dataTest?.collection_center_nup?.data?.nup?.data.id ?? undefined,
          _destroy: false,
        },
        notes: dataTest.notes ?? undefined,
      };
    }
  );
  return auxCCData;
}
export type FormFilterValuesCCInventory = {
  globalRange: DateRange;
  search: string;
  type_collection_center_id: string;
  state: string;
  campaign: string;
  wastes: string[];
  collector: boolean;
};

export const useCCInventory = (per_page: number) => {
  const { data } = useSession();
  const { toast, dismiss } = useToast();
  const { control, watch, reset, setValue, getValues } =
    useForm<FormFilterValuesCCInventory>({
      defaultValues: {
        globalRange: {
          from: undefined,
          to: undefined,
        },
        search: undefined,
        type_collection_center_id: undefined,
        state: "",
        campaign: undefined,
        wastes: [],
        collector: false,
      },
    });
  const {
    currentPage,
    firstPage,
    lastPage,
    nextPage,
    prevPage,
    last_page,
    paginationLabel,
    setTotal,
    setTotalPages,
    setCurrentPage,
    onChangePageSize,
    pageSize,
  } = usePagination(per_page);

  const {
    data: requestData,
    refetch,
    isFetching,
    isLoading
  } = useQuery({
    queryKey: [`cc_inventory`, currentPage, pageSize],
    enabled: data?.user?.token ? true : false,
    queryFn: async () => {
      const results = data?.user?.token
        ? await dataServices({
            url: "/collection_centers",
            params: {
              per_page: pageSize,
              page: currentPage,
              show_inactive: false,
              include_wastes: true,
              include_collection_center_wastes: true,
              include_address: true,
              include_collection_center_benefit: true,
              include_collection_center_nup: true,
              "include_collection_center_comments.comment": true,
              include_collection_center_comments: true,
              include_campaign_collection_centers: true,
              "include_campaign_collection_centers.campaign": true,
              "include_collection_center_socials.social_medium": true,
              "include_collection_center_contacts.contact": true,
              include_schedule_model: true,
              search: watch("search") || "",
              campaign_id: watch("campaign") || "",
              wastes_id: watch("wastes") || [],
              // center_type: watch("type_collection_center_id") || "",
              // collector: watch("collector") || false,
              state_id: watch("state") || "",
            },
            deserialize: true,
            token: data?.user?.token ?? undefined,
          })
        : null;
      const data_CC: RowCCInventory = getDataToCCInventory(results.data, data);
      return { data: data_CC, meta: { ...results.meta } };
    },
  });

  useEffect(() => {
    if (requestData) {
      setTotalPages(requestData.meta.total_pages);
      setTotal(requestData.meta.total_count);
    }
  }, [requestData]);

  function forceRefetchCCInventory() {
    setCurrentPage(1);
    refetch();
    toast({
      title: "Estamos buscando los centros de acopio",
      description: `Se estan cargando los centros de acopio con los parametros ingresados, espere un momento por favor`,
      variant: "warning",
    });
  }

  useEffect(() => {
    forceRefetchCCInventory();
  }, [
    watch("globalRange.from"),
    watch("globalRange.to"),
    watch("search"),
    pageSize,
    // watch("campaign"),
    // watch("wastes"),
    // watch("type_collection_center_id"),
    // watch("collector"),
    // watch("state"),
  ]);

  useEffect(() => {
    toast({
      title: "Estamos buscando los centros de acopio",
      description: `Se estan cargando los centros de acopio con los parametros ingresados, espere un momento por favor`,
      variant: "warning",
    });
  }, [isLoading]);

  async function onSubmitValidCC(dataCC: CC_Form) {
    const object_to_update = {
      collection_center: {
        id: dataCC.id,
        center_type: dataCC.center_type,
        collector: dataCC.collector,
        description: dataCC.description,
        name: dataCC.name,
        notes: dataCC.notes,
        ticket: dataCC.ticket,
        schedule_id: dataCC?.schedule_id,
        address_attributes: {
          id: dataCC?.address?.id,
          street: dataCC?.address?.street,
          exterior: dataCC?.address?.exterior,
          interior: dataCC?.address?.interior,
          lonlat: `POINT(${dataCC?.address?.lng} ${dataCC?.address?.lat})`,
          settlement_id: dataCC.address.settlement_id,
        },
        collection_center_wastes_attributes: dataCC.wastes?.map(
          (uni_waste) => ({
            id: uni_waste.id,
            waste_id: uni_waste.waste_id,
            _destroy: uni_waste._destroy,
          })
        ),
        campaign_collection_centers_attributes: dataCC.campaign?.map(
          (uni_campaign) => ({
            id: uni_campaign.id,
            campaign_id: uni_campaign.campaign_id,
            _destroy: uni_campaign._destroy,
          })
        ),
        collection_center_socials_attributes: dataCC.socials,
        collection_center_benefit_attributes: {
          id: dataCC.benefits_id,
          name: dataCC.benefits,
        },
        collection_center_contacts_attributes: [
          {
            id: dataCC.contact.id,
            contact_attributes: {
              id: dataCC.contact.id_contact,
              phone: dataCC.contact.phone,
              phone_ext: dataCC.contact.phone_ext,
            },
          },
        ],
        collection_center_nup_attributes: {
          id: dataCC.nup?.id,
          nup_id: dataCC.nup?.nup_id,
        },
      },
    };
    const formData = serialize(object_to_update);
    const response = await dataServices({
      url: `/collection_centers/${dataCC.id}`,
      requestType: "PATCH",
      apiVersion: "v2",
      body: formData,
      contentType: "multipart/form-data",
      token: data?.user?.token ?? undefined,
    }).catch((msg_err) => {
      console.log({ msg_err });
    });
    forceRefetchCCInventory();
  }

  async function onSubmitDeleteCC(idCC: string) {
    await dataServices({
      url: `/collection_centers/${idCC}`,
      requestType: "DELETE",
      apiVersion: "v2",
      token: data?.user?.token ?? undefined,
    });
    forceRefetchCCInventory();
  }

  async function handleActiveCC(dataCC: CCInventory) {
    try {
      const object_to_update = {
        collection_center: {
          active: !dataCC.active,
        },
      };
      const formData = serialize(object_to_update);
      const dataResponse = await dataServices({
        url: `/collection_centers/${dataCC.id}`,
        requestType: "PATCH",
        apiVersion: "v2",
        body: formData,
        contentType: "multipart/form-data",
        token: data?.user?.token ?? undefined,
      });
      forceRefetchCCInventory();
      toast({
        title: "Se ha ACTUALIZADO con éxito",
        description: `Se ACTUALIZO el centro de acopio con éxito `,
      });
    } catch (error) {
      console.error(error);
      toast({
        title: "Se ha producido un error al actualizar",
        description: `Se ha producido un error al actualizar, por favor, intente de nuevo más tarde`,
        variant: "destructive",
      });
    }
  }

  function handleResetFilterBar() {
    reset();
    // setValue("campaign", "");
    // setValue("collector", false);
    // setValue("globalRange", { from: undefined, to: undefined });
    // setValue("search", "");
    // setValue("state", "");
    // setValue("type_collection_center_id", "");
    // setValue("wastes", []);
  }

  return {
    requestData,
    control_date: control,
    reset_date: reset,
    pagination: {
      currentPage,
      firstPage,
      lastPage,
      nextPage,
      prevPage,
      paginationLabel,
      last_page,
      onChangePageSize,
      pageSize,
    },
    onSubmitDeleteCC,
    onSubmitValidCC,
    handleActiveCC,
    forceRefetchCCInventory,
    handleResetFilterBar,
  };
};

import { useQuery } from "@tanstack/react-query";
import { useSession } from "next-auth/react";
import { useGraphicsData } from "./useGraphicsData";
import { useCheckRefetch } from "./useCheckRefetch";
import { useToast } from "@/components/atoms/shadcn/ui/use-toast";
import { formatData, formatDataComplexNew, formatParamsForFilters } from "@/shared/functions/formData";
import { dataServices } from "./dataServices";
import { FetcherOverviewData } from "@/interfaces";

export async function fetcherOverviewDataMKTActivityTab({
    token,
    ranges,
    global,
    years,
  }: FetcherOverviewData) {
    const { localRanges, localYears } = formatParamsForFilters(
      ranges,
      global,
      years
    );
    const response = await dataServices({
      url: "/shopping_registrations/overview",
      token,
      params: {
        filters: {
          ...localRanges,
          ...localYears,
        },
      },
    });
    return {
      ...formatData(response.data),
      ...formatDataComplexNew(response.data, "history"),
    };
  }

export const useMKTActivityData = (
  keysRanges: string[],
  keysYear: string[],
  keysMonthWeek?: string[]
) => {
  const { data } = useSession();
  const { toast, dismiss } = useToast();
  const { control, reset, ranges, years, global } = useGraphicsData({
    keysRanges: keysRanges,
    keysYear: keysYear,
    keysMonthWeek: keysMonthWeek,
  });
  const { data: stats, refetch } = useQuery({
    queryKey: ["overviewShoppingRegistrations"],
    enabled: data?.user?.token ? true : false,
    queryFn: async () => {
      return data?.user?.token
        ? await fetcherOverviewDataMKTActivityTab({
            token: data?.user?.token!,
            ranges,
            global,
            years,
          })
        : null;
    },
  });
  useCheckRefetch(refetch, global, ranges, years);
  return {
    stats,
    control_filters: control,
    reset_filters: reset,
  };
};

import { useQuery } from "@tanstack/react-query";
import { dataServices } from "./dataServices";
import { usePagination } from "./usePagination";
import {
  CCCommentHistory,
  CCInventory,
  RowCCInventory,
  RowCCValidations,
  TicketResponse,
  Waste_validation,
  collectionCenterValidations,
} from "@/interfaces";
import { ShoppingRegistrationUniItem } from "@/interfaces/ticketsInterface";
import { FormFilterValuesCCValidations } from "./useCCValidations";
import { useForm } from "react-hook-form";
import { useSession } from "next-auth/react";
import { useEffect } from "react";

function getDataToCCCommentHistory(arr: any) {
  return arr.flatMap((dataTest: TicketResponse): CCCommentHistory => {
    return {
      id_cc_comment: dataTest.id,
      id_user: dataTest.recycler.data.id,
      user_name: dataTest.recycler.data.first_name,
      date_insert: new Date(dataTest.created_at).toLocaleDateString("es-MX"),
      comment: dataTest.comment ?? "",
      stars: dataTest.ecolanitas,
    };
  });
}

export const useCCHistoryComment = (per_page: number) => {
  const { data } = useSession();
  const { control, watch } = useForm<FormFilterValuesCCValidations>({
    defaultValues: {
      globalRange: {
        from: undefined,
        to: undefined,
      },
      search: "",
      campaign_id: "",
      type_collection_center_id: "",
    },
  });

  const {
    currentPage,
    firstPage,
    lastPage,
    nextPage,
    prevPage,
    last_page,
    paginationLabel,
    setTotal,
    setTotalPages,
    setCurrentPage,
    onChangePageSize,
    pageSize,
  } = usePagination(per_page);

  const { data: requestData, refetch } = useQuery({
    queryKey: ["ticket_grid", currentPage, pageSize],
    enabled: data?.user?.token ? true : false,
    queryFn: async () => {
      const results = data?.user?.token
        ? await dataServices({
            url: "/shopping_registrations",
            params: {
              state: "accepted",
              per_page: pageSize,
              page: currentPage,
              admins: true,
              "include_shopping_registration_item.product": true,
              include_recycler: true,
              include_shopping_place: true,
              start_date:
                watch("globalRange.from") !== undefined
                  ? watch("globalRange.from")?.toJSON()
                  : "",
              end_date:
                watch("globalRange.to") !== undefined
                  ? watch("globalRange.to")?.toJSON()
                  : "",
              search: watch("search") || "",
              //shopping_place_id: watch("place_shopping_id") || "",
            },
            token: data?.user?.token ?? undefined,
          })
        : null;
      const data_product: CCCommentHistory[] = getDataToCCCommentHistory(
        results.data
      );
      return { data: data_product, results, meta: { ...results.meta } };
    },
  });

  useEffect(() => {
    if (requestData) {
      setTotalPages(requestData.meta.total_pages);
      setTotal(requestData.meta.total_count);
    }
  }, [requestData]);

  function forceRefetchProducts() {
    setCurrentPage(1);
    refetch();
  }

  useEffect(() => {
    forceRefetchProducts();
  }, [pageSize]);

  return {
    requestData,
    control_date: control,
    pagination: {
      currentPage,
      firstPage,
      lastPage,
      nextPage,
      prevPage,
      paginationLabel,
      last_page,
      onChangePageSize,
      pageSize,
    },
  };
};

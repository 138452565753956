import { useRouter } from "next/router";

function useQueryParamAsBoolean(paramName: string): boolean {
  const router = useRouter();
  const { query } = router;

  return query[paramName] === "true";
}

export default useQueryParamAsBoolean;

import { useState, useEffect, useRef, useMemo } from "react";
import { Waste } from "@/interfaces/wastesInterface";
import { api } from "@/shared/api";
import { States, Towns } from "@/interfaces/statesTownsInterfaces";
import { CampaignsOptions } from "@/constants/campaignsData";
import { getActiveCampaigns } from "@/shared/functions/getActiveCampaigns";
import { useGeocode } from "./useGeocode";
import { MapRef } from "react-map-gl";
import { CollectionCenter } from "@/interfaces/collectionCenterInterface";
import { useToast } from "@/components/atoms/shadcn/ui/use-toast";
import { useQuery } from "@tanstack/react-query";
import { apiBaseURL, apiBaseURLV1 } from "@/constants";
import { useForm } from "react-hook-form";
import axios from "axios";
import { dataServices } from "./dataServices";

interface useMapDataProps {
  or_wastes?: boolean;
  initial_collection_center?: CollectionCenter[];
  origin?: string;
  campaign_id?: string;
  initial_or_waste_ids?: string[];
  section?: string;
  waste_id?: string;
  center_id?: string;
}

export interface FormFilterValues {
  state: string;
  town: string;
  campaign: string;
  wastes: string[];
  or_wastes: string[] | undefined;
}

export const useMapData = ({
  or_wastes = false,
  initial_or_waste_ids = [],
  initial_collection_center = [],
  origin = "map",
  campaign_id = "",
  section,
  waste_id = "",
  center_id = "",
}: useMapDataProps) => {
  const { control, watch, setValue } = useForm<FormFilterValues>({
    defaultValues: {
      state: "",
      town: "",
      campaign: campaign_id,
      wastes: waste_id ? [waste_id] : [],
      or_wastes: or_wastes ? initial_or_waste_ids : undefined,
    },
  });
  const { toast, dismiss } = useToast();
  const [collection_center, setCollection_center] =
    useState<CollectionCenter | null>(null);
  const mapRef = useRef<MapRef>(null);
  const { fromAddress } = useGeocode();
  const state_id = watch("state");
  const town_id = watch("town");
  const campaign = watch("campaign");
  const waste_ids = watch("wastes");
  const or_waste_ids = watch("or_wastes");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (
      origin === "re" &&
      or_waste_ids &&
      or_waste_ids.includes("4") &&
      !or_waste_ids.includes("12")
    ) {
      setValue("or_wastes", [...or_waste_ids, "12"]);
    }
    if (campaign_id !== "") setValue("campaign", campaign_id);
    if (waste_id !== "") setValue("wastes", [waste_id]);
  }, [or_waste_ids, campaign_id, waste_id]);

  const {
    data: collection_centers,
    isFetching,
    isRefetching,
    isLoading,
    isFetchedAfterMount,
    refetch,
  } = useQuery({
    queryKey: [`collection_centers" + ${origin}`],
    queryFn: async () => {
      await axios.post(`${apiBaseURL}/analytics/create_history_maps`, {
        state_id: state_id,
        town_id: town_id,
        campaign_id: campaign,
        waste_ids: waste_ids,
        or_waste_ids: or_waste_ids,
        section: section,
      });
      const response = await axios.get(
        `${apiBaseURLV1}/collection_centers/custom_map`,
        {
          params: {
            state_id: state_id,
            town_id: town_id,
            campaign_id: campaign,
            waste_ids: waste_ids,
            or_waste_ids: or_waste_ids,
            section: section,
          },
        }
      );
      return response.data;
    },
    initialData: initial_collection_center,
  });
  const { data: states } = useQuery<States[]>({
    queryKey: ["states"],
    queryFn: async () =>
      (
        await axios.get(`${apiBaseURLV1}/collection_centers/obtain_states`)
      ).data.data.flatMap((uniState: any) => {
        return { id: uniState.id, name: uniState.attributes.name };
      }),
  });
  const { data: wastes } = useQuery<Waste[]>({
    queryKey: ["wastes", state_id, or_waste_ids],
    queryFn: async () =>
      (
        await axios.get(
          `${apiBaseURL}/wastes?per_page=100&page=1&sort=validations`
        )
      ).data.data.flatMap((uniWaste: any) => {
        return {
          id: uniWaste.id,
          name: uniWaste.attributes.name,
          icon: uniWaste.attributes.icon,
        };
      }),
  });
  const { data: campaigns } = useQuery<CampaignsOptions[]>({
    queryKey: ["campaigns"],
    queryFn: async () => await getActiveCampaigns(),
  });
  const { data: towns } = useQuery<Towns[]>({
    queryKey: ["towns", state_id],
    queryFn: async ({ queryKey }) =>
      (
        await axios.get(
          `${apiBaseURLV1}/collection_centers/obtain_towns?state_id=${queryKey[1]}`
        )
      ).data.data.flatMap((uniTown: any) => {
        return { id: uniTown.id, name: uniTown.attributes.name };
      }),
    enabled: state_id != "",
  });

  const showToastAlert = () => {
    setMapCenter();
    toast({
      title: "No hay centros de acopio en la zona.",
      description: `Lo siento, no hemos encontrado un centro de acopio en esta zona con los filtros requeridos, por favor, realiza otra búsqueda.`,
    });
  };

  const showProgressToast = () => {
    return toast({
      title: "Estamos buscando los centros de acopio",
      description: `Agradecemos su espera, estamos buscando los centros de acopio con los filtros solicitados`,
    }).id;
  };

  useEffect(() => {
    if (center_id != "") {
      handleIsOpenDialog(center_id);
    }
  }, [center_id]);

  const getMapPosition = async () => {
    if (mapRef.current && (state_id != "" || town_id != "")) {
      const name_state = states?.find(
        (uni_state) => uni_state.id == state_id
      )?.name;
      const name_town = towns?.find((uni_town) => uni_town.id == town_id)?.name;
      const address_text = name_town
        ? `${name_town}, ${name_state}, México`
        : `${name_state}, México`;
      const response = await fromAddress(address_text);
      const bounds =
        response.results[response.results.length - 1].geometry.bounds;
      mapRef.current?.fitBounds([
        { lat: bounds.southwest.lat, lng: bounds.southwest.lng },
        { lat: bounds.northeast.lat, lng: bounds.northeast.lng },
      ]);
    }
  };

  async function handleIsOpenDialog(id: string) {
    try {
      const results = await dataServices({
        url: `/collection_centers/${id}`,
        params: {
          include_wastes: true,
          include_collection_center_wastes: true,
          include_address: true,
          include_collection_center_benefit: true,
          include_collection_center_nup: true,
          "include_collection_center_comments.comment": true,
          include_collection_center_comments: true,
          include_campaign_collection_centers: true,
          "include_campaign_collection_centers.campaign": true,
          "include_collection_center_socials.social_medium": true,
          "include_collection_center_contacts.contact": true,
          include_schedule_model: true,
          "include_schedule_model.schedule_days": true,
        },
        deserialize: true,
      });
      setCollection_center(results.data);
      setIsOpen(!isOpen);
    } catch (error) {
      console.error(error);
    }
  }
  function handleCloseDialog() {
    setIsOpen(!isOpen);
  }
  const memoList = useMemo(() => collection_centers, [collection_centers]);

  const handleSearch = async () => {
    const idToast = showProgressToast();
    const response = await refetch();
    if (response.data.length === 0) {
      dismiss(idToast);
      showToastAlert();
    } else {
      dismiss(idToast);
      if (state_id != "" || town_id != "") {
        getMapPosition();
      } else {
        setMapCenter();
      }
    }
  };

  function setMapCenter() {
    if (campaign_id === "36") {
      mapRef.current?.setCenter({
        lat: -36.414663957800666,
        lng: -64.19696194486185,
      });
      mapRef.current?.setZoom(3);
    } else {
      mapRef.current?.setCenter({
        lat: 24.13686865831984,
        lng: -102.4256922299597,
      });
      mapRef.current?.setZoom(4);
    }
  }

  return {
    states: states || [],
    towns: towns || [],
    wastes: wastes || [],
    campaigns: campaigns || [],
    mapRef,
    collection_centers: memoList,
    handleIsOpenDialog,
    handleCloseDialog,
    isOpen,
    setIsOpen,
    collection_center,
    control,
    setFormValue: setValue,
    watch,
    handleSearch,
  };
};

import {
  geocode,
  setKey,
  setLanguage,
  setRegion,
  setLocationType,
  fromAddress,fromLatLng
} from "react-geocode";

export const useGeocode = () => {
  setKey("AIzaSyAVbvhzp547wtdYUaxbcCYPhrHL_UNMDDw");
  setLanguage("es");
  setRegion("mx");
  setLocationType("ROOFTOP");
  return {fromAddress, fromLatLng};
};

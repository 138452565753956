// Generated by https://quicktype.io

export interface Waste {
  slug: string;
  id: string;
  type: string;
  waste_informations: WasteInformations;
  name: string;
  description: Description;
  active: boolean;
  platform_visible: string;
  icon: string | null;
  selected: boolean;
}

export enum Description {
  Kg = "Kg",
  Null = "null",
  The000000000 = "0.00000000",
}

export interface WasteInformations {
  data: Datum[];
}

export interface Datum {
  id: string;
  type: DatumType;
  waste_property: WasteProperty;
  info: Description | null;
}

export enum DatumType {
  WasteInformation = "waste_information",
}

export interface WasteProperty {
  data: Data;
}

export interface Data {
  id: string;
  type: DataType;
  name: string;
}

export enum DataType {
  WasteProperty = "waste_property",
}

export type WasteId = {
  id: string;
  name: string;
};

export type WasteCategoriesId = {
  id: string;
  name: string;
};

export interface WastePerCategoryID {
  id:     string;
  name:   string;
  wastes: WasteId[];
}

import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";
import { signIn } from "next-auth/react";

interface IFormLoginInput {
  email: string;
  password: string;
}
export const useLoginForm = (error: string) => {
  const loginSchema = z.object({
    email: z
      .string()
      .email({ message: "Email inválido" })
      //.regex(/@ecolana\.com\.mx$/, "Email inválido")
      .min(1, { message: "El email es requerido" }),
    password: z
      .string()
      .min(4, { message: "La contraseña debe tener al menos 4 caracteres" }),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: zodResolver(loginSchema),
  });
  const onSubmit: SubmitHandler<IFormLoginInput> = async ({
    email,
    password,
  }) => {
    await signIn("credentials", {
      username: email,
      password: password,
      callbackUrl: "/admins/escaner/overview",
    });
  };
  return { control, handleSubmit, errors, onSubmit };
};

// Generated by https://quicktype.io
import { WasteInformations } from "./wastesInterface";
export interface CollectionCenter {
  id: string;
  type: string;
  address: Address;
  collection_center_benefit: CollectionCenterBenefit;
  contacts: Contacts;
  wastes: Wastes;
  name: string;
  description: null;
  schedule: string;
  schedule_model: ScheduleModel;
  score: null;
  center_type: string;
  active: boolean;
  ticket: string;
  collector: boolean;
  notes: null;
  image?: string;
  qr_code?: string;
  stars?: string;
}

export interface Address {
  data: AddressData;
}

export interface AddressData {
  id: string;
  type: string;
  street: string;
  exterior: string;
  interior: string;
  comment: null;
  lat: number;
  lng: number;
  pin: string;
  text_address: string;
}

export interface CollectionCenterBenefit {
  data: CollectionCenterBenefitData;
}

export interface CollectionCenterBenefitData {
  id: string;
  type: string;
  name: string;
}

export interface Contacts {
  data: ContactsDatum[];
}

export interface ContactsDatum {
  id: string | null;
  type: string | null;
  phone: string | null;
  phone_ext: string | null;
  contact_type: number;
}

export interface Wastes {
  data: WastesDatum[];
}

export interface WastesDatum {
  id: string;
  type: PurpleType;
  waste_informations: WasteInformations;
  name: string;
  description: string;
  active: boolean;
  platform_visible: PlatformVisible;
  icon: null | string;
  selected: boolean;
}

export enum PlatformVisible {
  VisibleWebVisibleApp = "visible_web_visible_app",
}

export enum PurpleType {
  Waste = "waste",
}

export interface PropsCenter {
  address: string;
  wastes: (string | undefined)[];
  benefit: string | null;
  schedule: string | null;
  name: string;
  mapsLink: string;
  wazeLink: string;
  phone: string | null;
  id: string;
  description: string | null;
}

export interface Waste_validation {
  id_waste_valdation?: string;
  quantity?: string;
  waste_category_id?: string;
  waste_category_name?: string;
  waste_id?: string;
  waste_name?: string;
  _destroy?: boolean;
}

export interface CampaignsCC {
  id: string;
  campaign_id?: string;
  name?: string;
  active?: boolean;
}
export interface collectionCenterValidations {
  id_cc_validation: string;
  evidence?: string;
  date_insert: string;
  id_user?: string;
  user_name?: string;
  name_collection_center?: string;
  adress_collection_center?: string;
  campaign_id?: string;
  campaign_name?: string;
  wastes_validations: Waste_validation[];
  comment?: string;
}

export interface CCCommentHistory {
  id_cc_comment: string;
  id_user?: string;
  user_name?: string;
  date_insert: string;
  comment?: string;
  stars?: string;
}

// Define los tipos e interfaces
interface ScheduleDay {
  day: string;
  end_time: string;
  start_time: string;
  id: string;
  type: string;
}

interface ScheduleDays {
  data: ScheduleDay[];
}

interface ScheduleModelData {
  description: string;
  id: string;
  name: string;
  schedule_days: ScheduleDays;
}

interface ScheduleModel {
  data: ScheduleModelData;
}

export interface CCInventory {
  id: string;
  name?: string;
  stars?: number;
  state_name?: string;
  state_id?: string;
  type_cc_id?: string;
  type_cc_name?: string;
  ticket?: string;
  schedule_id?: string;
  wastes: Waste_validation[];
  campaigns: CampaignsCC[];
  nup: { id: undefined; nup_id: ""; _destroy: false };
  address: {
    id: string;
    street?: string;
    exterior?: string;
    interior?: string;
    lng?: number;
    lat?: number;
    state_id?: string;
    town_id?: string;
    zipcode_id?: string;
    settlement_id?: string;
  };
  comments: {
    id?: string;
    description?: string;
    created_at?: string;
    score?: string;
  }[];
  contact?: {
    id_contact?: string;
    id?: string;
    phone?: string;
    phone_ext?: string;
    contact_type?: string;
  };
  socials: {
    id?: string;
    social_medium_attributes: {
      id?: string;
      site?: string;
      url?: string;
    };
  }[];
  isCollector?: boolean;
  benefit?: { id?: string; name?: string };
  active: boolean;
  notes?: string;
}

export type RowCCValidations = collectionCenterValidations[];
export type GridCCValidations = RowCCValidations[];

export type RowCCInventory = CCInventory[];

import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ExpandedState, Row } from "@tanstack/react-table";
import { RejectedValidation } from "@/interfaces/validationsInterfaces";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

type useTransactionStatesProps<T> = {
  resetData: (data: T) => void;
};

export const useTransactionStates = <T>({
  resetData,
}: useTransactionStatesProps<T>) => {
  const [showGrid, setShowGrid] = useState(true);
  const [idxScan, setIdxScan] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [isOpenRejected, setIsOpenRejected] = useState(false);
  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [rowIndex, setRowIndex] = useState<number>();
  const [expanded, setExpanded] = useState<ExpandedState>({});

  const schema = z.object({
    rejected_category: z
      .string({
        required_error: "Categoría de rechazo: Es un campo es requerido, ",
      })
      .optional(),
    new_rejected_category: z
      .string()
      .optional(),
    description: z
      .string({ required_error: "Descripción: Es un campo es requerido" })
      .optional(),
  });

  const {
    control: rejected_control,
    handleSubmit: rejected_handleSubmit,
    watch: rejected_watch,
    reset: rejected_reset,
    formState: { errors: rejected_errors },
  } = useForm<RejectedValidation>({
    resolver: zodResolver(schema),
    defaultValues: {
      rejected_category: "",
      new_rejected_category: "",
      description: "",
    },
  });

  function getIdCellv2(row: Row<T[]>, idx: number) {
    const idx_rowExpanded = Object.keys(expanded);
    setIdxScan(idx);
    if (idx_rowExpanded.length == 0) {
      row.toggleExpanded(!row.getIsExpanded());
      setRowIndex(row.index);
      resetData(row.original[idx]);
    } else if (rowIndex === row.index) {
      if (idxScan === idx) {
        row.toggleExpanded(!row.getIsExpanded());
      } else {
        setRowIndex(row.index);
        resetData(row.original[idx]);
      }
    } else {
      setExpanded({});
      setRowIndex(row.index);
      resetData(row.original[idx]);
      row.toggleExpanded(!row.getIsExpanded());
    }
  }

  function getRowIndex(data: T) {
    resetData(data);
    setIsOpen(!isOpen);
  }

  function handleCloseDialog() {
    setIsOpen(!isOpen);
  }
  function handleCloseConfirm() {
    setIsOpenConfirm(!isOpenConfirm);
  }
  function handleCloseRejected() {
    setIsOpenRejected(!isOpenRejected);
  }
  function handleCloseFilters() {
    setIsOpenFilters(!isOpenFilters);
  }
  function handleRemove() {
    rejected_reset();
    setIsOpenConfirm(!isOpenConfirm);
  }
  function handleRejected() {
    rejected_reset();
    setIsOpenRejected(!isOpenRejected);
  }

  useEffect(() => {
    setRowIndex(0);
    setIdxScan(0);
  }, [showGrid]);

  return {
    showGrid,
    setShowGrid,
    idxScan,
    rowIndex,
    getIdCellv2,
    rejected_control,
    rejected_watch,
    rejected_reset,
    rejected_handleSubmit,
    rejected_errors,
    isOpen,
    isOpenConfirm,
    isOpenRejected,
    handleCloseDialog,
    handleCloseConfirm,
    handleCloseRejected,
    handleRemove,
    handleRejected,
    getRowIndex,
    expanded,
    setExpanded,
    isOpenFilters,
    handleCloseFilters,
  };
};
